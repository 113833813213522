.endAdornment {
  height: 52px;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 52px;

  svg {
    height: 100%;
    width: 100%;
  }
}
