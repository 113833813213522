.title {
  text-transform: capitalize;

  .line1, .line2 {
    display: block;
  }

  .line1 {
    margin-bottom: 4px;
  }
}