@import '../../styles/settings/variables';

$button-primary: $text-color;
$button-secondary: $colour-white;

.loadMoreWrapper {
  display: flex;
  flex: 0 0 auto;
  height: 40px;
  justify-content: center;
  margin: $grid-vertical-gutter 0 ($grid-vertical-gutter * 2);
  width: 100%;
}

.button {
  background: $button-secondary;
  border: {
    color: $button-primary;
    style: solid;
    width: 1px;
  }
  color: $button-primary;
  width: auto;

  &:hover {
    background: $button-primary;
    color: $button-secondary;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
