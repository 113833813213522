@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

.productList {
  background-color: $colour-scallop-grey;
  padding-top: 10px;

  @include media-breakpoint-up('md') {
    padding-top: $ingredients-gutter;
  }

  &.contentComponent {
    background-color: $colour-scallop-grey;
  }
}

.flexGrid {
  margin-left: -8px;
  margin-right: -8px;

  &.inGroups{
    margin-left: 0;
    margin-right: 0;
  }
  
  @include media-breakpoint-up('sm') {
    display: flex;
    flex-wrap: wrap;
  }
}

.spinnerWrapper {
  min-height: $product-grid-minimum-height;
  position: relative;
  margin-top: 50px;
}

