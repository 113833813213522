@use "sass:math";

@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

$colour-error: $colour-waitrose-error-red;
$colour-input-active: $colour-primary-grey;
$colour-input-border: $colour-earl-grey;
$colour-label: $colour-waitrose-grey;
$colour-placeholder: $colour-earl-grey;
$font-size-label: 16px;
$font-size-label-displaced: 14px;
$form-font-size: 16px;
$margin-bottom: 20px;
$padding-vertical: 16px;
$padding-horizontal: 12px;
$transition-timing-fast: .2s;
$transition-timing-slow: .4s;
$input-line-height: 1.3333;
$input-height: 56px;
$input-border-width: 1px;
$input-border-width-focus: 2px;
$textarea-height: 132px;
$textarea-line-height: 1.5;
$textarea-border-width: $input-border-width;

%container {
  margin-bottom: $margin-bottom;
  position: relative;
}

@mixin checkbox-control(
  $checkbox-size: 24px,
  $checkbox-horizontal-padding: 0,
  $checkbox-label-gap: 16px
) {
  line-height: $checkbox-size + 4;
  padding-left: $checkbox-horizontal-padding + $checkbox-size + $checkbox-label-gap;
  user-select: none;

  &::before {
    background-color: $colour-white;
    border: 1px solid $text-color;
    box-shadow: 0 0 4px $colour-transparent;
    content: '';
    display: block;
    height: $checkbox-size;
    left: $checkbox-horizontal-padding;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: border-width $transition-timing-fast;
    width: $checkbox-size;

    :checked + & {
      background-color: $text-color;
      border-width: $checkbox-size * 0.5;
      transition: background-color $transition-timing-fast, border-width 0s $transition-timing-fast;
    }

    .invalidText & {
      border-color: $colour-error;
    }

    .invalidText :checked + & {
      background-color: $colour-error;
    }

    :disabled + & {
      border-color: $colour-oyster-grey;
    }

    :disabled:checked + & {
      background-color: $colour-oyster-grey;
    }
  }

  &::after {
    $border-width: 2px;

    border: {
      bottom: $border-width solid $colour-white;
      left: $border-width solid $colour-white;
      right: 0;
      top: 0;
    }
    content: '';
    height: $border-width;
    left: $checkbox-horizontal-padding;
    margin: ($checkbox-size * 0.25) ($checkbox-size * 0.25);
    opacity: 0;
    position: absolute;
    top: 47%;
    transform: translateY(-50%) rotate(-50deg);
    transform-origin: 0 0;
    width: $border-width;

    :checked + & {
      $timing: $transition-timing-fast * 0.5;

      height: ($checkbox-size * 0.25);
      opacity: 1;
      transition: height $timing $timing, width $timing $transition-timing-fast;
      width: math.div($checkbox-size, 1.9);
    }
  }
}

@mixin radio-control(
  $radio-size: 20px,
  $radio-horizontal-padding: 20px,
  $radio-label-gap: 10px,
  $radio-vertical-padding: 12px
) {
  $radio-border-width: 1px;
  $radio-inner-spacing: 7px;
  $radio-inner-size: $radio-size - ($radio-border-width + $radio-inner-spacing);

  border: 1px solid $colour-oyster-grey;
  display: block;
  padding: {
    bottom: $radio-vertical-padding;
    left: ($radio-horizontal-padding + $radio-size + $radio-label-gap);
    right: $radio-horizontal-padding;
    top: $radio-vertical-padding;
  }
  position: relative;
  transition: border-color $transition-timing-fast;
  user-select: none;

  :checked + & {
    border-color: $text-color;
  }

  .invalidText & {
    border-color: $colour-error;
  }

  &::before {
    background-color: $colour-white;
    border: $radio-border-width solid $text-color;
    border-radius: 50%;
    content: '';
    display: block;
    height: $radio-size;
    left: $radio-horizontal-padding;
    margin-top: -1 * ($radio-size * 0.5);
    position: absolute;
    top: 50%;
    width: $radio-size;

    :disabled + & {
      border-color: $colour-oyster-grey;
    }
  }

  &::after {
    background-color: $text-color;
    border-radius: 50%;
    content: '';
    height: $radio-inner-size;
    left: $radio-horizontal-padding + 4;
    margin-top: -1 * ($radio-inner-size * 0.5);
    position: absolute;
    top: 50%;
    transform: scale(0);
    transition: transform $transition-timing-fast;
    width: $radio-inner-size;

    :checked + & {
      transform: scale(1);
    }

    :disabled:checked + & {
      background-color: $colour-oyster-grey;
    }
  }
}

.radioGroupContainer {
  @extend %container;
}

.radioGroupHeading {
  font: {
    family: Waitrose;
    size: $font-size-base;
    weight: 200;
  }
  margin-bottom: $margin-bottom * 0.5;
}

.radioButtons {
  display: block;
}

.inlineRadios {
  font: {
    size: 20px;
    weight: 400;
  }
  text-align: center;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .#{$breakpoint}InlineRadios {
      display: flex;
      justify-content: center;

      .input {
        margin: {
          bottom: 0;
          left: 8px;
          right: 8px;
          top: 0;
        }
      }
    }
  }
}

@mixin label-focus {
  font: {
    size: $font-size-label-displaced;
    weight: 500;
  }
  top: 8px;
}
