@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../layout';

.wrapper {

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 ($ingredients-unit*2) $ingredients-gutter;
  

  @include media-breakpoint-up('sm') {
    flex-direction: row;
    margin: 0 ($ingredients-gutter*3) $ingredients-gutter*.5;
    padding: $ingredients-gutter $ingredients-unit*3;
  }

  .button {
    height: $ingredients-gutter*2;
    
    margin: 0 $ingredients-unit*2 $ingredients-gutter*.5; 

    @include media-breakpoint-up('sm') {
      width: 100%;
    }

  }
}
