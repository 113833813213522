@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../styles/settings/variables';
@import '../../wdx/mixins/typography';

.listItem {
  list-style: none;
  margin: 0 0 10px;
}

.label {
  @include paragraph('medium');
  
  overflow: hidden;
  text-align: left;
  width: 100%;
}

.name {
  overflow: hidden;
  padding-right: $ingredients-unit * 2;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.count {
  @include paragraph('light');
  padding-right: $ingredients-unit * 2;
  white-space: nowrap;
}
