@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../../wdx/mixins/typography';
@import '../../../wdx/common';

@import '../../../ListsModal/layout';

.addButton {
  align-items: center;
  background: none;
  border: 0;
  color: $colour-waitrose-grey;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-decoration: underline;
  width: 100%;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.label {
  width: 100%;

  @include media-breakpoint-up('md') {
    padding: 0 0 4px 3px;
    
  }
}

.icon {
  margin-right: 16px;
  margin-bottom: -3px;

  @include media-breakpoint-up('md') {
    margin-top: 8px;
  }
}
