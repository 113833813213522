@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';

.placeHolder {
  color: $colour-waitrose-grey;

  svg {
    background: $colour-oyster-grey;
    height: 80px;
    width: 80px;
  }
}
