@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';
@import '../../../styleguide/styleguide';
@import '../variables';

%wrapper {
  display: flex;
  flex: {
    basis: auto;
    grow: 0;
    shrink: 0;
    direction: row;
    wrap: nowrap;
  }
}

.wrapper {
  @extend %wrapper;
  justify-content: flex-start;
}

.wrapperCompact {
  @extend %wrapper;
  justify-content: center;
}

.flipper {
  @extend %flipper;
}


.btnCTA {
    svg {
      width: 14px;
      height: 14px;
    }

  &:nth-child(-n + 3) {
    margin-right: 8px;
  }

  &.hide {
    display: none;
  }
}

.form {
  display: flex;
  grid-column: 2;
  grid-row: 1 / 3;
  align-items: flex-end;

  @include media-breakpoint-up('sm') {
    grid-column: initial;
    grid-row: initial;
  }
}

.inputWrapper {
  display: flex;
  border: 1px solid $colour-primary-grey;
  font-size: $font-size-base;
  font-weight: 400;
  height: $large-add-to-trolley;
  min-width: $large-add-to-trolley;
  max-width: 85px;
  margin: 0 8px 0 0;
  padding: 0 10px;
  flex-grow: 1;
  cursor: text;

  @include media-breakpoint-up('sm') {
    font-size: $font-body-size-small;
  }

  &.hide {
    display: none;
  }

  &:focus-within {
    box-shadow: none;
    outline-color: $colour-focus-blue;
    outline-offset: 4px;
    outline-style: solid;
    outline-width: 4px;
  }

  .inputUom {
    display: none;
  }

  &.showUom {
    & .inputUom {
      display: flex;
      align-items: center;
      height: 100%;
      text-align: left;
      color: $colour-earl-grey;
    }
  }

  .input {
    appearance: textfield;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: inherit;
    height: 100%;
    margin: 0;
    text-align: left;
    width: 100%;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

.updateBtn {
  max-width: 88px;
  min-width: 88px;

  &.hide {
    display: none;
  }
}

.updated {
  border: 1px solid $colour-success-green;
  background: $colour-success-green-tint;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
