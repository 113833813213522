@use "sass:math";

@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/mixins/typography';

%panel {
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  width: 100%;
}

.buttonPanel {
  @extend %panel;
  margin-top: $ingredients-unit * 4;
  border-top: 1px solid $colour-mushroom-grey;

  @include media-breakpoint-up('md') {
    margin-top: 0;
  }
}

.headerPanel {
  @extend %panel;
  margin-bottom: $ingredients-unit * 4;
  border-bottom: 1px solid $colour-mushroom-grey;
}

.heading {
  @include title-modal;
  align-items: center;
  display: flex;
  height: 52px;
  justify-content: center;
  width: 100%;
}

.lists {
  margin: 0 ($ingredients-unit * 4);
  padding: 0;
  text-align: left;

  @include media-breakpoint-up('md') {
    margin: 0 calc(100% / 8);
    max-width: 525px;
  }

  @include media-breakpoint-up('lg') {
    margin: 0 calc(100% / 6);
  }
}

.formHidden {
  border: 1px dashed $colour-primary-grey;
  margin-bottom: $ingredients-gutter * 3;
}

.modal {
  @mixin columns-of-12($cols) {
    max-width: #{(math.div(1200px, 12) * $cols)};
    width: #{math.div($cols, 12) * 100}vw;
  }

  @include columns-of-12(12);
  height: 100%;

  @include media-breakpoint-up('md') {
    @include columns-of-12(8);
    height: 80vh;
  }

  @include media-breakpoint-up('lg') {
    @include columns-of-12(6);
  }
}

.message {
  @include paragraph;
  margin: {
    bottom: $ingredients-gutter * 2;
    left: $ingredients-gutter * 2;
    right: $ingredients-gutter * 2;
    top: $ingredients-gutter;
  }

  @include media-breakpoint-up('md') {
    margin: {
      left: $ingredients-gutter * 2;
      right: $ingredients-gutter * 2;
    }
  }
}

.name {
  @include paragraph('bold');
}

.panels {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.scrollablePanel {
  @extend %panel;
  border-bottom: 1px solid $colour-mushroom-grey;
  flex: {
    grow: 1;
    shrink: 1;
  }
  overflow: auto;
  width: 100%;

  @include media-breakpoint-up('sm') {
    border-bottom: 0;
  }
}
