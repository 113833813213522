@import '@johnlewispartnership/wtr-ingredients/foundations/colours';

.header {
    background-color: $colour-waitrose-grey;
    color: $colour-white;
    display: flex;

    h2 {
        margin: 0;
        padding: 12px 0 12px 32px;
    }
}

.list {
    margin-bottom: 0;
    padding-left: 0;
}