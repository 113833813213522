@import '../../styles/tools/mixins';

$small-add-to-trolley: 40px;
$small-add-to-trolley-line-height: $small-add-to-trolley - 2px;
$large-add-to-trolley: 40px;
$large-add-to-trolley-line-height: $large-add-to-trolley - 2px;

%trolley-button {
  font-family: WaitroseGlyph;
  height: $large-add-to-trolley;
  line-height: $large-add-to-trolley-line-height;
  padding: 0;
  width: $large-add-to-trolley;

  @include media-breakpoint-up('sm') {
    height: $small-add-to-trolley;
    line-height: $small-add-to-trolley-line-height;
    width: $small-add-to-trolley;
  }

  &:disabled {
    border: 0;
    opacity: 100;
  }
}
