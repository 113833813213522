@import '../inputs';


.field {
  position: relative;
}

.input {
  @include boxed-control;
}
