$ingredients-unit: 4px;
$ingredients-gutter: $ingredients-unit * 5;

$grid-breakpoints: (
  'xs': 0,
  'sm': 544px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px
) !default;
