@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';

.products {
  position: relative;
  z-index: 1;
  min-height: 60vh;

  @include media-breakpoint-up('md') {
    margin-top: 0;
  }
}

.productGroups {
  list-style: none;
  padding-left: 0;
}

.productGroup {
  margin-bottom: 24px;
  width: 100%;

  .productGroupTitle {
    padding: 16px 0 0;
    margin-left: 4px;

    &.withBorder{
      border-top: 1px solid $colour-mushroom-grey;
    }
  }

  &:first-of-type {
    .productGroupTitle {
      border-top: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.builderTitleContainer {
  text-align:center;
  display: flex;
  justify-content: center;

  .line {
    height: 1px;
    background-color: $colour-chia-grey;
    flex-grow: 1;
    align-self: center;
    margin-bottom:10px;
  }

  .builderTitle{
    width: fit-content;
    padding: 0 20px;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 400;
  }
}
