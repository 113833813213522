@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '../../../styles/tools/mixins';
@import '../../../styles/settings/variables';

$fade-animation: fade 0.25s;

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        }
}

.product {
    display:flex;
    flex-direction: column;
    border: 1px solid $colour-oyster-grey;
    padding: 20px;
    margin: 20px;

    > p {
        margin-bottom: 0;
    }
    @include media-breakpoint-up('lg') {
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
    }
    animation: $fade-animation;
    animation-fill-mode: backwards;
    animation-delay: 0.8s;
    
    &.unavailableProduct{
        flex-direction: row;
        animation: none;
      }
} 




.productInfo {
    display: flex;
    align-items: center;
    width: 100%;

    .titleLink {
        display: block;
    }

    .productTitle {
        margin: 0;
    }

    .infoContainer {
        width: calc(100% - 20px);
        padding-left: 20px;
        padding-right: 8px;
    }

    a {
        &:focus {
          @include focus;
        }
      }

      @include media-breakpoint-up('lg') {
        margin-right: 20px;
        border-right: 1px solid $colour-oyster-grey;
        width: 55%;
        padding: 16px 0;
    }

    &.unavailableProductInfo {
        @include media-breakpoint-up('lg') {
            width: 55%;
        }
      }
}

.thumbnailWrapper {
    align-self: baseline;

    &,
    img {
      height: auto;
      width: 48px;
    }

    &.largeThumbnailWrapper {
        &,
        img {
            height: auto;
            width: 88px;

            @include media-breakpoint-up('sm') {
                width: 112px;
            }
        }
    }

    @include media-breakpoint-up('lg') {
      align-self: center;
    }
}

.actionContainer {
    display:flex;
    flex-direction: column;

    @include media-breakpoint-up('lg') {
        width: 40%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .trolleyControllsContainer 
    {
        margin-top: 20px;
        width: 154px;
        align-self: flex-end;

        @include media-breakpoint-up('lg') {
            margin-top: 0;
            align-self: center;
        }
    }

}

.prices {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media-breakpoint-up('lg') {
        flex-direction: column;
        align-items: baseline;
    }
}

.removeBtnContainer {
    height: 40px;
    width: 40px;
    background-color: $colour-white;
    position: absolute;
    right: 25px;
}

.priceMsg {
    margin: 0;
}

.typicalWeight {
    margin-left: 4px;
}

.itemPriceSection {
    display: flex;
    
    @include media-breakpoint-up('lg') {
        flex-direction: column;
    }

    .price {
        margin:0;
        margin-right: 5px;
    }
}

.pricePerUnit {
    margin-bottom: 0;
    align-self: center;
}

.unavailablePrice {
    flex-direction: row;
    align-items: center;
    width: calc(100% - 20px);
    justify-content: space-between;
    
    .itemPriceSection {
        align-items: center;
        flex-direction: row;
    }
}

.promotions {
    p:last-of-type {
        margin-bottom: 0;
    }
}
