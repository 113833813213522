@import '@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables";

.modal {
    border-top: solid 4px $colour-waitrose-green;
    width: 100%;
    height: 100%;
    max-width: unquote('min(800px, max(768px, 80.5vw))');
    max-height: 100vh;
  
    @include media-breakpoint-up('md') {
      position: fixed;
      max-height: 95vh;
    }
  }

.modal-content {
    text-align: left;
    padding: 0;
  }

.buttonWrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    box-shadow: 0 -4px 4px 0 $colour-primary-grey--alpha-40;
    width: 100%;
    height: 80px;
    background-color: $colour-white;
    padding: 20px 16px;

    @include media-breakpoint-up('md') {
      padding: 20px;
      justify-content: flex-end;
    }
}