%flipper {
  perspective: 1000px;
  position: relative;
}

%flip-face {
  backface-visibility: hidden;
  background-color: inherit;
  left: 0;
  top: 0;
  transform-style: preserve-3d;
  transition: {
    property: transform;
    duration: .6s;
  }
}

%front-face {
  @extend %flip-face;
  z-index: 2;
}

%back-face {
  @extend %flip-face;
  position: absolute;
}

%front-face-vertical {
  @extend %front-face;
  transform: rotateX(0deg);
}

%back-face-vertical {
  @extend %back-face;
  transform: rotateX(180deg);
}

%front-face-horizontal {
  @extend %front-face;
  transform: rotateY(0deg);
}

%back-face-horizontal {
  @extend %back-face;
  transform: rotateY(180deg);
}
