@use "sass:math";

@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../styles';

$border-width: 1px;
$colour-active: $colour-primary-grey;
$colour-inactive: $colour-earl-grey;
$colour-error: $colour-waitrose-error-red;

@mixin inset-border ($border-colour, $size: $border-width) {
  box-shadow: 0 0 0 $size $border-colour inset;
}

@mixin control-input ($size: 1.5rem, $padding: 0) {
  height: 1px;
  left: 0;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  top: 0;
  width: 1px;

  + label {
    padding-left: ($size * 1.5) + $padding !important;
    position: relative;

    &::before {
      background-color: $colour-white;
      border: $border-width solid $colour-active;
      content: '';
      display: block;
      height: $size;
      left: $padding;
      position: absolute;
      top: 50%;
      width: $size;
    }

    &::after {
      content: '';
      position: absolute;
    }
  }

  &:disabled {
    + label {
      opacity: .2;
    }
  }

  &:not(:disabled) {
    + label {
      cursor: pointer;
    }
  }

  &[type='checkbox'] {
    + label {
      &::before {
        box-shadow: 0 0 $ingredients-unit $colour-transparent;
        transform: translateY(-50%);
        transition: border-width $transition-timing-fast;
      }

      &::after {
        $border-width: $ingredients-unit * 0.5;

        border: {
          bottom: $border-width solid $colour-white;
          left: $border-width solid $colour-white;
          right: 0;
          top: 0;
        }
        height: $border-width;
        left: $padding;
        margin: ($size * 0.25) ($size * 0.25);
        opacity: 0;
        top: 48%;
        transform: translateY(-50%) rotate(-50deg);
        transform-origin: 0 0;
        width: $border-width;
      }
    }

    &:checked {
      + label {
        &::before {
          background-color: $colour-active;
          border-width: $size * 0.5;
          transition: background-color $transition-timing-fast, border-width 0s $transition-timing-fast;
        }

        &::after {
          $timing: $transition-timing-fast * 0.5;

          height: ($size * 0.25);
          opacity: 1;
          transition: height $timing $timing, width $timing $transition-timing-fast;
          width: math.div($size, 1.9);
        }
      }
    }
  }

  &[type='radio'] {
    $inner-spacing: math.div($size, 3);
    $inner-size: math.div($size, 3) * 2;

    + label {
      &::before {
        border-radius: 50%;
        margin-top: -($size * 0.5);
      }

      &::after {
        background-color: $colour-active;
        border-radius: 50%;
        height: $inner-size;
        left: $padding + ($inner-spacing * 0.5);
        margin-top: -($inner-size * 0.5);
        top: 50%;
        transform: scale(0);
        transition: transform $transition-timing-fast;
        width: $inner-size;
      }
    }

    &:checked {
      + label {
        &::before {
          border-color: $colour-active;
        }

        &::after {
          transform: scale(1);
        }
      }
    }
  }
}

@mixin control ($size: 1.5rem, $padding: 0) {
  @include control-input($size, $padding);

  &:focus {
    + label {
      &::before {
        @include focus-outline;
      }
    }
  }
}

@mixin boxed-control ($size: 1.5rem, $padding: 1rem) {
  @include control-input($size, $padding);

  &:focus {
    + label {
      @include focus-outline;
    }
  }

  &:checked {
    + label {
      border-color: $colour-active;
    }

    &:focus {
      + label {
        @include focus-outline;
      }
    }
  }

  &[aria-invalid='true'] {
    + label {
      border-color: $colour-error;
    }
  }

  &:not(:disabled) {
    + label {
      &:hover {
        @include inset-border($colour-inactive);
      }
    }

    &:checked {
      + label {
        &:hover {
          @include inset-border($colour-active);
        }
      }
    }

    &[aria-invalid='true'] {
      + label {
        &:hover {
          @include inset-border($colour-error);
        }
      }
    }
  }

  + label {
    border: $border-width solid $colour-inactive;
    display: block;
    padding: $ingredients-unit * 4;
    width: 100%;
  }
}
