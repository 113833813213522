@import '../../../ListsModal/layout';

.disabled {
  &,
  & input:disabled {
    cursor: wait;
  }
}

.form {
  display: flex;
}

.cardPlacement {
  padding: 0 20px;
}

.name {
  flex-grow: 1;
  margin-top: 20px;
}

.submit {
  align-self: center;
  display: inline-block;
  height: 40px;
  width: 120px;
  margin: {
    bottom: 12px;
    left: 20px;
  }
}
