@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../FormFields';
@import '../../../../components/wdx/animation';

.inputContainer,
.textareaContainer {
  @extend %container;
}

.textareaContainer {
  [class*='subText'] {
    position: relative;
    top: 28px;
  }
}

.fieldContainer {
  background-color: $colour-eggshell-grey;

  &::after {
    background-color: $colour-input-active;
    content: '';
    display: block;
    height: $input-border-width-focus;
    left: 50%;
    position: absolute;
    top: $input-height - $input-border-width-focus;
    transform: translateX(-50%);
    transition: all $transition-timing-fast;
    width: 0;
  }

  .textareaContainer &::after {
    top: $textarea-height - $textarea-border-width;
  }

  .textareaContainer & {
    height: $textarea-height;
  }

  .textareaContainer &:hover {
    background-color: $colour-scallop-grey;
  }

  .hasFocus &,
  .invalid & {
    &:not(:disabled) {
      &::after {
        width: 100%;
      }
    }
  }

  .invalid & {
    &:not(:disabled) {
      &::after {
        background-color: $colour-error;
      }
    }
  }
}

.input {
  appearance: none;
  background-color: $colour-transparent;
  border: 0;
  border-bottom: $input-border-width solid $colour-input-border;
  border-radius: 0;
  color: $text-color;
  font: {
    family: Waitrose;
    size: $form-font-size;
    weight: 300;
  }
  height: $input-height;
  line-height: $input-line-height;
  outline: 0;
  padding: $padding-vertical $padding-horizontal 0;
  transition: all $transition-timing-fast;
  width: 100%;

  &:not(:disabled) {
    &:focus {
      outline: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: $colour-scallop-grey;
  }
}

::placeholder {
  color: $colour-placeholder;
}

.password {
  padding-right: 40px;
}

.passwordToggle {
  background: {
    color: $colour-transparent;
    image: none;
  }
  border: 0;
  color: $colour-earl-grey;
  display: inline-block;
  cursor: pointer;
  font: {
    family: Waitrose;
    size: 12px;
    weight: 300;
  }
  line-height: $form-font-size * $input-line-height;
  padding: 0;
  position: absolute;
  right: $padding-horizontal;
  text-decoration: underline;
  top: $padding-vertical + 2px;
  touch-action: manipulation;

  &.errorPresent {
    right: $padding-horizontal + 32px;
  }
}

.textarea {
  -ms-overflow-style: none;
  appearance: none;
  background-color: $colour-transparent;
  border: 0;
  border-bottom: $input-border-width solid $colour-input-border;
  color: $text-color;
  display: block;
  font: {
    family: Waitrose;
    size: $form-font-size;
    weight: 300;
  }
  height: $textarea-height - 28px;
  line-height: $textarea-line-height;
  outline: 0;
  padding: 0 $padding-horizontal 8px;
  position: relative;
  resize: none;
  top: 28px;
  transition: all $transition-timing-fast;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &:not(:disabled) {
    &:focus {
      outline: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  .invalid & {
    border-color: $colour-error !important;
  }
}

.label {
  color: $colour-label;
  font: {
    size: $font-size-label;
    weight: 300;
  }
  left: $padding-horizontal;
  line-height: 1;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  transform-origin: 0 0;
  transition: all $transition-timing-fast;

  .hasFocus &,
  .hasValue & {
    @include label-focus;
  }

  .invalid & {
    animation: $wdx-shake-animation;
  }

  // Cannot be combined with the selectors above as it breaks the rule in Firefox, hence the mixin
  :-webkit-autofill ~ & {
    @include label-focus;
  }

  .invalid :not(:disabled) + & {
    color: $colour-error;
  }
}

.errorIcon {
  color: $colour-waitrose-error-red;
  animation: $wdx-fade-in-animation;
  opacity: 0;
  position: absolute;
  right: 12px;
  top: 18px;
}
