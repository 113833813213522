@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import "~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins";

$fade-animation: fade 0.1s;

.header {
    background-color: $colour-mushroom-grey;
    display: flex;

    h2 {
        margin: 0;
        padding: 12px 0 12px 32px;
    }
}

.productsWrapper{
  margin-bottom: 80px;
  padding-left: 0;

  @include media-breakpoint-up('md') {
    margin-bottom: 100px;
  }
}

.spinnerWrapper {
    margin-top: 50px;
}

.emptySection {
  padding: 40px 20px 0;
  text-align: center;
}

@keyframes fade {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }